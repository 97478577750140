var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <tr class=\"release-schedule-content\">\n                        <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"issueVolume") || (depth0 != null ? lookupProperty(depth0,"issueVolume") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"issueVolume","hash":{},"data":data,"loc":{"start":{"line":21,"column":28},"end":{"line":21,"column":43}}}) : helper)))
    + "</td>\n                        <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"issueYear") || (depth0 != null ? lookupProperty(depth0,"issueYear") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"issueYear","hash":{},"data":data,"loc":{"start":{"line":22,"column":28},"end":{"line":22,"column":41}}}) : helper)))
    + "</td>\n                        <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"plannedShipDate") || (depth0 != null ? lookupProperty(depth0,"plannedShipDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"plannedShipDate","hash":{},"data":data,"loc":{"start":{"line":23,"column":28},"end":{"line":23,"column":47}}}) : helper)))
    + "</td>\n                        <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"actualShipDate") || (depth0 != null ? lookupProperty(depth0,"actualShipDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"actualShipDate","hash":{},"data":data,"loc":{"start":{"line":24,"column":28},"end":{"line":24,"column":46}}}) : helper)))
    + "</td>\n                    </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div id=\"release-schedule-body-mask\"></div>\n<div id=\"release-schedule-journal-modal\">\n    <div id=\"release-schedule-modal-header\">\n        <button id=\"release-schedule-modal-close\" aria-label=\"Close modal popup\" type=\"button\"><span aria-hidden=\"true\">&times;</span></button>\n        <h3 id=\"release-schedule-modal-heading\">Publication Schedule</h3>\n        <h4 class=\"publication-title\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":38},"end":{"line":6,"column":47}}}) : helper)))
    + "<h4>\n    </div>\n    <div id=\"release-schedule-modal-body\">\n        <table>\n            <thead>\n                <tr id=\"release-schedule-table-header\">\n                    <th>Issue Volume</th>\n                    <th>Issue Year</th>\n                    <th>Planned Ship Date</th>\n                    <th>Actual Ship Date</th>\n                </th>\n            </thead>\n            <tbody>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"releaseSchedule") || (depth0 != null ? lookupProperty(depth0,"releaseSchedule") : depth0)) != null ? helper : alias2),(options={"name":"releaseSchedule","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":26,"column":36}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"releaseSchedule")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </tbody>\n        </table>\n    </div>\n    <div id=\"release-schedule-modal-footer\">\n        <a id=\"release-schedule-faq\" href=\"https://service.elsevier.com/app/answers/detail/a_id/28273/supporthub/ecommerce\">Learn more</a>\n    </div>\n</div>\n";
},"useData":true});