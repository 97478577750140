const css = require('../sass/style.scss');
const releaseScheduleTemplate = require('../views/main.hbs');

let journalModal;
let bodyMask;
let bodyElement;
let closeButton;
const modalButtonSelector = 'release-schedule-open-modal';

const issns = [];
const openModalButtons = document.getElementsByClassName(modalButtonSelector);
Array.prototype.forEach.call(openModalButtons, function (openModalButton) {
    issns.push(openModalButton.dataset.issn);
});

if (issns.length) {
    const httpRequest = new XMLHttpRequest();
    httpRequest.open('GET', dataUrl + constructQueryString(issns));
    httpRequest.send();
    httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState === XMLHttpRequest.DONE && httpRequest.status === 200) {
            const results = JSON.parse(httpRequest.responseText).result;
            results.forEach((result) => {
                let openModalButton = document.querySelector(
                    `.${modalButtonSelector}[data-issn="${result.issn}"]`
                );
                if (!openModalButton) {
                    const issn = result.issn.replace('-', '');
                    openModalButton = document.querySelector(
                        `.${modalButtonSelector}[data-issn="${issn}"]`
                    );
                }
                openModalButton.style.display = 'block';

                openModalButton.addEventListener(
                    'click',
                    function (e) {
                        e.stopPropagation();
                        e.preventDefault();
                        showModal(result);
                    },
                    false
                );
            });
        }
    };
}

function showModal(data) {
    const modalContainer = getModalContainer();
    modalContainer.innerHTML = releaseScheduleTemplate(data);
    setEventHandlers();
    openModal();
}

function closeModal() {
    journalModal.style.display = 'none';
    bodyMask.style.display = 'none';
}

function openModal() {
    journalModal.style.display = 'block';
    bodyMask.style.display = 'block';
}

function constructQueryString(issns) {
    const queryParts = issns.map((issn) => `issn=${issn}`);
    return `?${queryParts.join('&')}`;
}

function getModalContainer() {
    let modalContainer = document.getElementById('release-schedule-container');
    if (!modalContainer) {
        modalContainer = document.createElement('div');
        modalContainer.setAttribute('id', 'release-schedule-container');
        document.body.appendChild(modalContainer);
    }
    return modalContainer;
}

function setEventHandlers() {
    closeButton = document.getElementById('release-schedule-modal-close');
    journalModal = document.getElementById('release-schedule-journal-modal');
    bodyMask = document.getElementById('release-schedule-body-mask');
    bodyElement = document.getElementsByTagName('body')[0];

    closeButton.addEventListener(
        'click',
        function () {
            closeModal();
        },
        false
    );

    window.onclick = function (event) {
        if (event.target === bodyMask) {
            closeModal();
        }
    };
}
